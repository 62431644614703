<template>
  <div class="christmas-saver">

    <h1>Christmas Savings</h1>
    <b-col cols="12" md="5" class="d-block mx-auto my-0 p-0 pl-md-2 mb-3 float-md-right">
     <b-img fluid rounded src="/tiles/xmas.png" alt="Christmas" />
    </b-col>
    <p>Joining our Christmas savings scheme makes sense when planning ahead for the festive season.</p>
    <p>Save throughout the year and we will pay your savings directly into your bank account early in November.</p>

    <p>If you are already a member and wish to join our Christmas Savings scheme please <a href="mailto:enquiries@cashbox.org.uk">email us</a> to advise how much you wish to save.</p>
    <p>If you are not already a member and wish to join and save for Christmas please...</p>

    <p>
      <a
          class="btn btn-lg btn-success"
          href="https://cashbox.cuaccount.com/join/"
          target="_blank"
      >Join Us</a
      >
    </p>

  </div>
</template>

<script>
export default {
  name: 'Christmas Savings',
  metaInfo() {
    return {
      title: 'Christmas Savings | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
